import request from "@/utils/request";

export function getheavycar(params){
    return request({
        url:"truckMonitoring/bsnTruckMonitoring/list",
        method:"get",
        params
    })
}

export function gettrackcar(params){
    return request({
        url:"trackDown/bsnTrackDown/list",
        method:"get",
        params
    })
}

export function getstopcar(params){
    return request({
        url:"trackStop/bsnTruckStop/list",
        method:"get",
        params
    })
}

export function getvideocar(data){
    return request({
        url:"cameraDistribution/cameraDistribution/queryByVehicleno",
        method:"get",
        params:{
            vehicleno:data
        }
    })
}

//获取轨迹
// http://47.107.119.62:8089/tdcs-boot/trackingTrucks/bsnTrackingTrucks/list
export function getbsnTrackingTrucks(params){
    return request({
        url:"trackingTrucks/bsnTrackingTrucks/list",
        method:"get",
        params
    })
}
