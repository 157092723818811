import Router from "vue-router"
import Vue from "vue"
Vue.use(Router)

const router = new Router({
    routes:[
        {
            path:"/",
            name:"index",
            component:()=>import("@/view/homePage")
        }
    ]
})


export default router