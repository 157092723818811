 
import axios from 'axios';
 
// 基本配置
const request = axios.create({
  baseURL: 'https://kehu.appmfht.hellomky.cn/', // 根据实际情况修改API地址
  timeout: 50000 // 设置超时时间，单位为ms
});
 
// 请求拦截器
request.interceptors.request.use(config => {
  config.headers['Authorization'] = localStorage.getItem('token'); // 设置请求头部分，这里举例使用了localStorage存储的token作为身份标识
  return config;
}, error => {
  console.log(error);
  // return Promise.reject(error);
});
 
// 响应拦截器
request.interceptors.response.use(response => {
  const data = response.data;

      return data;
  
}, error => {
  console.log(error);
  // return Promise.reject(error);
});
 
export default request;
 