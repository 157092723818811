import request from "@/utils/request2";

export function add(data){
    return request({
        url:"/api/school/chepai/add",
        method:"post",
        data
    })
}
export function info(params){
    return request({
        url:"/api/school/chepai/info",
        method:"get",
        params
    })
}

export function list(params){
    return request({
        url:"/api/school/chepai/list",
        method:"get",
        params
    })
}


// export function gettrackcar(params){
//     return request({
//         url:"trackDown/bsnTrackDown/list",
//         method:"get",
//         params
//     })
// }

// export function getstopcar(params){
//     return request({
//         url:"trackStop/bsnTruckStop/list",
//         method:"get",
//         params
//     })
// }

// export function getvideocar(data){
//     return request({
//         url:"cameraDistribution/cameraDistribution/queryByVehicleno",
//         method:"get",
//         params:{
//             vehicleno:data
//         }
//     })
// }

// //获取轨迹
// // http://47.107.119.62:8089/tdcs-boot/trackingTrucks/bsnTrackingTrucks/list
// export function getbsnTrackingTrucks(params){
//     return request({
//         url:"trackingTrucks/bsnTrackingTrucks/list",
//         method:"get",
//         params
//     })
// }
