import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$bus = new Vue()

import router from './router';
import Element from 'element-ui';
import vant from 'vant'
import 'vant/lib/index.css';

Vue.use(vant)
Vue.use(router)
Vue.use(Element, { size: 'small', zIndex: 3000 });

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
