<template>
  <div id="app">
    <el-container>
      <el-aside :width="asideWidth">
        <!-- 数据列表 -->
        <aside-part   @update-datalist="handleUpdateDatalist"></aside-part>
      </el-aside>
      <el-container>
        <el-header>
            <header-part 
            @exportWord ="exportWord"
            
            @toggle="Toggleshow"
                    @treeData="getTreeData"
            @startTracking="startTracking"
            @searchClick="searchClick"
            @togglePlotsVisibility="togglePlotsVisibility"
            ></header-part>
        </el-header>
        <el-main>
            <!-- <router-view></router-view> -->
             <homePage 
             :datalist="datalist" 
             ref="childComponent"
             >
            </homePage>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import asidePart from "@/components/asidePart.vue"
import headerPart from "@/components/headerPart.vue"
import homePage from "@/view/homePage.vue"
export default {
  components:{
    asidePart,headerPart,homePage
  },
  name: "App",
  data(){
    return{
      asideWidth:"150px",
      show:true,
      datalist: {}
    }
  },
  methods:{
    togglePlotsVisibility(data){
             // 通过 ref 调用子组件的方法
             this.$refs.childComponent.togglePlotsVisibility(data);
    },
    handleUpdateDatalist(updatedData) {
      console.log('Received data from child:', updatedData);
      this.datalist = updatedData;
    },
    Toggleshow(){
      if(this.show){
        this.asideWidth = "0px"
      }else{
        this.asideWidth = "150px"
      }
      this.show = !this.show
    },
    searchClick(data){
        // 通过 ref 调用子组件的方法
        this.$refs.childComponent.seachTrackData(data);
    },
    exportWord(){
      this.$refs.childComponent.exportWord();
    },
    getTreeData(data){
      this.$refs.childComponent.getTreeData(data);
    },
    //type1回放 2 开始 3暂停 4结束 speed速度
    startTracking(data){
      console.log('%c [  ]-63', 'font-size:13px; background:pink; color:#bf2c9f;', )
      this.$refs.childComponent.startTracking(data);
    }
  },


};
</script>

<style>
  .el-header, .el-footer {
    /* background-color: #B3C0D1; */
    /* color: #333; */
    height: 100px !important;
    border-bottom: 1px solid #ddd;
    /* line-height: 60px; */
  }
  
  .el-aside {
    height: 100vh;
    border-right: 1px solid #ddd;
    padding: 5px;
    /* box-sizing: border-box; */
  }
  
  .el-main {
    padding: 5px !important;
    background-color: #fff;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
</style>
