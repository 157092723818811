<template>
  <div class>
    <el-button type="primary" @click="toggle()" class="show">{{ this.asideopen ? "收起" : "展开" }}</el-button>
    <div class="type1 type2">
      <div class="top">
        <div class="lable">
          <div class="key">车牌号码</div>
          <div class="input">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="lable">
          <div class="key">车牌颜色</div>
          <div class="input">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="type1 type4">
      <div class="top">
        <div class="lable">
          <div class="key">播放速度</div>
          <div class="input">
            <el-select v-model="value1" placeholder="请选择">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="lable">
          <div class="key">停留时间</div>
          <div class="input">
            <el-input v-model="input1" placeholder="请输入内容">
              <template slot="append">分钟以上</template>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="type1 type3">
      <div class="lefticon">
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="top">
        <div class="lable">
          <div class="key">开始时间</div>
          <div class="input">
            <el-date-picker v-model="input2" style="width:140px" type="date" placeholder="选择日期"></el-date-picker>
            <!-- <el-input placeholder="请选择日期" suffix-icon="el-icon-date" v-model="input2"></el-input> -->
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="lable">
          <div class="key">结束时间</div>
          <div class="input">
            <el-date-picker v-model="input3" type="date" style="width:140px" placeholder="选择日期"></el-date-picker>
            <!-- <el-input placeholder="请选择日期" suffix-icon="el-icon-date" v-model="input3"></el-input> -->
          </div>
        </div>
      </div>
    </div>
    <el-button class="search" type="primary" icon="el-icon-search" @click="searchClick()">查询轨迹</el-button>
    <div class="opwapper">
      <div class="operate">
        <el-button type="primary" icon="el-icon-video-play" @click="startTracking(1)">开始</el-button>
        <el-button type="primary" icon="el-icon-video-play" @click="startTracking(2)">继续</el-button>
      </div>
      <div class="operate">
        <el-button type="primary" icon="el-icon-video-pause" @click="startTracking(3)">暂停</el-button>
        <el-button type="primary" icon="el-icon-video-pause" @click="startTracking(4)">加速</el-button>
      </div>
    </div>

    <div class="opwapper">
      <div class="operate">
        <el-button type="primary" icon="el-icon-video-play" @click="openDialog">
          地块展示选择
        </el-button>
      </div>
      <div class="operate">
        <el-button type="primary" icon="el-icon-video-play" @click="exportWord">
          生成举报附件
        </el-button>
      </div>
    </div>


    <el-dialog title="选择地块展示" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
          <el-checkbox 
            v-model="launch" 
            @change="menuTreeLaunch">展开/折叠</el-checkbox>
          <el-checkbox 
            v-model="selectAll" 
            @change="menuTreeSelectAll">全选/全不选</el-checkbox>
   
          <el-tree 
            ref="tree" 
            :data="treeData" 
            :props="{ label: 'label', children: 'children' }"
            show-checkbox 
            node-key="id" 
            :default-checked-keys="form.allSelectedIds"
            :check-strictly="!form.menuCheckStrictly"
            class="tree-border"
          >
          </el-tree>


      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {

            launch: false,       // 展开/折叠
            selectAll: false,    // 全选/全不选
            form:{
                menuCheckStrictly:true,    // 是否遵循父子不相互关联 （true为遵循父子不相互关联）
                allSelectedIds:[],        // 全选中的菜单id数组
                halfSelectedIds:[],       // 半选中的菜单id数组
            },

      dialogVisible: false, // 控制弹出框的显示与隐藏
      treeData: [
        // {
        //   id:1,
        //   label: '合山市2023年糖料蔗良种补贴地块kml数据',
        //   checked: false,
        //   path: '合山市2023年糖料蔗良种补贴地块kml数据',
        //   children: [
        //     {
        //       id: 11, label: '地块kml解密文件-北泗镇地块', checked: false,
        //       path: '地块kml解密文件-北泗镇地块',
        //       children: [
        //         {
        //           id: 111, label: '01.何荣辉.01031203001.桂糖44号.果树改扩种.02.2.6.4.kml', checked: false,
        //           path: '01.何荣辉.01031203001.桂糖44号.果树改扩种.02.2.6.4.kml',
        //         },
        //         {
        //           id: 112, label: '91.黎鉴进.01010809095.桂柳05136.桉树改扩种.03.1.34.9.kml', checked: false,
        //           path: '91.黎鉴进.01010809095.桂柳05136.桉树改扩种.03.1.34.9.kml',
        //         }
        //       ],
        //     },

        //   ],
        // }, 
        //  {
        //   id:2,
        //   label: '来宾市-象州县-广西博华食品有限公司-糖料蔗种植地块数据成果',
        //   checked: false,
        //   path: '来宾市-象州县-广西博华食品有限公司-糖料蔗种植地块数据成果',
        //   children: [
        //     {
        //       id: 21, label: '11040608201罗书荣.kml', 
        //       checked: false,
        //       path: '11040608201罗书荣.kml',
        //     },
        //   ],
        // }
        {
          id:1,
          label: '周界',
          checked: false,
          path: '周界',
          children: [
            {
              id: 21, label: '某嫌疑糖厂周界.kml', 
              checked: false,
              path: '某嫌疑糖厂周界.kml',
              name:'某嫌疑糖厂周界'
            },
            {
              id: 22, label: '蔗区周界1127.kml', 
              checked: false,
              path: '蔗区周界1127.kml',
              name:'蔗区周界'
            },
          ],
        }
      ],
      checkedKeys: [],  // 用于绑定选中的节点
      defaultProps: {
        children: 'children',
        label: 'label',
        disabled: 'disabled',  // 如果需要禁用某些节点，可以加这个字段
      },

      checked: false,
      input: "",
      input1: "",
      input2: "",
      input3: "",
      options: [
        { label: "黑色", value: "black" },
        { label: "白色", value: "white" }
      ],
      options1: [
        { label: "1", value: 500 },
        { label: "1.5", value: 1000 },
        { label: "3", value: 2000 },
        { label: "10", value: 3000 }
      ],

      value: "",
      value1: 500,
      asideopen: true
    };
  },
  watch: {
    // 监听 checked 变化
    checked(newValue) {
      // 调用计算函数来处理地块显示与否
      this.togglePlotsVisibility(newValue);
    }
  },
  components: {},
  created() { },
  mounted() {
    this.$bus.$on("opentable", val => {
      let data = val[0];
      // console.log(data);
      this.input = data.vehicleno;
      this.input2 = data.initialTime;
      this.input3 = data.updateTime;
    });
  },
  methods: {
        /** 展开菜单树 */
   menuTreeLaunch(e) {
        // e 为 true 或 false 表示展开按钮的选中状态
        for (let i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
          // 遍历 el-tree 的每个节点，将节点的 expanded 属性设为 true 或 false
          this.$refs.tree.store._getAllNodes()[i].expanded = e
        }
    },

       /** 全选菜单树 */
       menuTreeSelectAll(e) {
      // e 为 true 或 false 表示全选按钮的选中状态
      if (e) {
        // 全选
        this.$refs.tree.setCheckedNodes(this.treeData)
      } else {
        // 全不选
        this.$refs.tree.setCheckedNodes([])
      }
    },
      /** 菜单树父子联动 */
      menuTreeLinkage(e) {
      // e 为 true 或 false 表示联动按钮的选中状态
      this.form.menuCheckStrictly = e
    },



    // 当节点的选中状态发生变化时
    handleCheckChange(node, checked) {
      console.log('checked:', checked); // 选中的布尔值
      console.log('node:', node); // 当前节点
      if (node.children) {
        // 如果当前节点有子节点，父子联动
        this.updateChildrenCheckedStatus(node.children, checked);
      } else {
        // 如果是子节点，检查父节点是否需要更新
        this.updateParentCheckedStatus(node);
      }


    },
 // 更新所有子节点的 checked 状态
 updateChildrenCheckedStatus(children, checked) {
      children.forEach(child => {
        child.checked = checked;
        // 如果子节点有子节点，则递归更新
        if (child.children) {
          this.updateChildrenCheckedStatus(child.children, checked);
        }
      });
    },

    // 更新父节点的 checked 状态
    updateParentCheckedStatus(childNode) {
      // 查找父节点
      let parentNode = this.findParentNode(this.treeData, childNode.id);
      if (parentNode) {
        const allChildrenChecked = parentNode.children.every(child => child.checked);
        parentNode.checked = allChildrenChecked;
      }
    },

    // 递归查找父节点
    findParentNode(nodes, childId) {
      for (let node of nodes) {
        if (node.children) {
          for (let child of node.children) {
            if (child.id === childId) {
              return node; // 找到父节点
            }
          }
          // 递归查找子节点
          const parent = this.findParentNode(node.children, childId);
          if (parent) return parent;
        }
      }
      return null; // 没有找到父节点
    },

  
    openDialog() {
      this.dialogVisible = true;
    },
    exportWord(){
      this.$emit("exportWord");
    },
   
    handleConfirm() {

      console.log('%c [  ]-231', 'font-size:13px; background:pink; color:#bf2c9f;', this.treeData)
      console.log('%c [  ]-231', 'font-size:13px; background:pink; color:#bf2c9f;', this.form)
       
      let tree = this.$refs.tree;
      let array = tree.getCheckedKeys().concat(tree.getHalfCheckedKeys())
      console.log('%c [ array ]-308', 'font-size:13px; background:pink; color:#bf2c9f;', array)
      this.dialogVisible = false;
      this.$emit("treeData", {
        treeData: this.treeData,
        selectIds: array
      });
    },

    handleClose(done) {
      done();
    },

    togglePlotsVisibility(data) {
      console.log('%c [ data ]-145', 'font-size:13px; background:pink; color:#bf2c9f;', data)
      this.$emit("togglePlotsVisibility", data);
    },
    searchClick() {

      console.log('%c [  ]-132', 'font-size:13px; background:pink; color:#bf2c9f;',)
      // input1 停留时间 input2开始时间 input3 结束时间
      this.$emit("searchClick", {
        stopTime: this.input1,
        startTime: this.input2,
        endTime: this.input3
      });
    },
    startTracking(type) {
      //1回放 2 开始 3暂停 4结束 speed速度 
      this.$emit("startTracking", {
        type: type,
        speed: this.value1

      });
    },
    toggle() {
      this.asideopen = !this.asideopen;
      this.$emit("toggle", "0");
    }
  }
};
</script>

<style lang='scss' scoped>
.show {
  float: left;
  margin-top: 10px;
}

.type1 {
  user-select: none;
  width: 250px;
  margin: 10px;
  float: left;

  .top,
  .bottom {
    margin-top: 1px;

    .lable {
      background-color: #fff;
      height: 32px;
      width: 250px;

      .key {
        width: 70px;
        float: left;
        font-size: 12px;
        line-height: 32px;
        text-align: center;
        background-color: #e8eaed;
      }

      .input {
        float: left;
        width: 150px;
      }
    }
  }
}

.type2 {
  width: 160px;

  .top,
  .bottom {
    .lable {
      .key {
        width: 60px;
      }

      .input {
        width: 100px;
      }
    }
  }
}

.type4 {
  width: 220px;

  .top,
  .bottom {
    .lable {
      .key {
        width: 60px;
      }

      .input {
        width: 160px;
      }
    }
  }
}

.type3 {
  width: 260px;

  .lefticon {
    width: 20px;
    height: 64px;
    float: left;
    margin-top: 2px;
    background-color: #4096d1;
    line-height: 64px;
    text-align: center;

    i {
      color: #fff;
    }
  }
}

.search {
  float: left;
  margin-top: 20px;
  width: 100px;
  height: 44px;
}

.opwapper {
  float: left;
  margin: 5px;
}

.operate {
  // float: left;
  margin-top: 5px;
  height: 34px;
  margin-left: 10px;
}
</style>